import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    ArtistPage: dynamic(() => import('./ArtistPage')),
    ArtistListPage: dynamic(() => import('./ArtistListPage')),
    BasePage: dynamic(() => import('./BasePage')),
    CampaignPage: dynamic(() => import('./CampaignPage')),
    DistributorsPage: dynamic(() => import('./DistributorsPage')),
    HomePage: dynamic(() => import('./HomePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    ProductsArchivePage: dynamic(() => import('./ProductsArchivePage')),
    ProductPage: dynamic(() => import('./ProductPage')),
    PageListPage: dynamic(() => import('./PageListPage')),
    AccessoryPage: dynamic(() => import('./AccessoryPage')),
    SoundArchivePage: dynamic(() => import('./SoundArchivePage')),
    PianoLibraryPage: dynamic(() => import('./PianoLibraryPage')),
    ComparePage: dynamic(() => import('./ComparePage')),
    StreamFieldPage: dynamic(() => import('./StreamFieldPage')),
    SoftwarePage: dynamic(() => import('./SoftwarePage')),
    SignatureSoundBankPage: dynamic(() => import('./SignatureSoundBankPage')),
    SampleLibraryPage: dynamic(() => import('./SampleLibraryPage')),
    SoundCollectionPage: dynamic(() => import('./SoundCollectionPage')),
};
